import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import FormProvider from '../Form/context';
import { withCart } from '../Cart/context';
import CheckoutProvider from './context';

import Container from '../Container';
import PageTitle from '../Subpage/title';
import Layout from './layout';
import Placeholder from '../Placeholder';

import { MainColour } from '../../utils/variables';

import loadComponents from '../Loadable';

const Button = loadComponents('button');

export default withCart(function Checkout({ count /* , data */ }) {
  /* const layoutProps = {
    shippingMethods: data.wp.shippingOptions,
  }; */
  return (
    <>
      <PageTitle title={count > 0 ? 'Checkout' : 'Your basket is empty'} />
      <Container>
        {count > 0 ? (
          <FormProvider>
            <ElementsConsumer>
              {({ stripe, elements }) => {
                const checkoutProps = {
                  stripe,
                  elements,
                };
                return (
                  <CheckoutProvider {...checkoutProps}>
                    <Layout /* {...layoutProps} */ />
                  </CheckoutProvider>
                );
              }}
            </ElementsConsumer>
          </FormProvider>
        ) : (
          <Button
            to="/"
            style={{ width: `350px`, maxWidth: `100%` }}
            fallback={
              <Placeholder
                width="350px"
                height="58px"
                colour={MainColour}
                style={{ borderRadius: `100px` }}
              />
            }
            primary
          >
            Return to Homepage
          </Button>
        )}
      </Container>
    </>
  );
});
